import React from 'react'
import * as Sentry from '@sentry/node'
import BootstrapProvider from '@bootstrap-styled/provider/lib/BootstrapProvider';
import '../assets/style.scss?plain';

import theme from '../components/Theme'
import 'moment/locale/en-gb'
import moment from 'moment-timezone'
import Head from 'next/head'
import Router from 'next/router';
import NProgress from 'nprogress';
import GoogleMapProvider from '../components/GoogleMapProvider'
import BookingFormProvider from '../components/booking/BookingFormProvider'

moment.tz.setDefault('Australia/Sydney')
moment.locale('en-gb')

Sentry.init({
  dsn: typeof window !== 'undefined' ? process.env.REACT_APP_SENTRY_DSN : process.env.REACT_APP_SENTRY_DSN_SSR,
  environment: process.env.REACT_APP_APP_STAGE,
})

//Binding events.
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function MyApp({ Component, pageProps }) {

  if (typeof window !== 'undefined') {
    const head = document.getElementsByTagName('head')[0]
    const insertBefore = head.insertBefore
    head.insertBefore = function (newElement, referenceElement) {
      if (newElement.href && newElement.href.indexOf('//fonts.googleapis.com/css?family=Roboto') > -1) {
        console.info('Prevented Roboto from loading!')
        return
      }
      insertBefore.call(head, newElement, referenceElement)
    }
  }

  return (
    <BootstrapProvider theme={theme}>
      <Head>
        <meta name="theme-color" content="#ffffff"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
      </Head>
      <GoogleMapProvider>
        <BookingFormProvider>
          <Component {...pageProps} />
        </BookingFormProvider>
      </GoogleMapProvider>
    </BootstrapProvider>
  )
}

export default MyApp
